const AddTags = ({ tags, setTags }) => {
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      const value = e.target.value;
      if (!value.trim()) return;
      setTags([...tags, value]);
      e.target.value = "";
    }
  }

  function removeTag(index) {
    setTags(tags.filter((i) => i !== index));
  }

  return (
    <div className="flex flex-col gap-4">
      <input
        className="p-2"
        type="text"
        autoFocus
        placeholder="Type some text then press Enter"
        onKeyDown={handleKeyDown}
      />

      <div className="flex items-center gap-2 flex-wrap cursor-pointer">
        {tags &&
          tags.map((tag, i) => (
            <div key={i} onClick={() => removeTag(i)}>
              {tag}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AddTags;

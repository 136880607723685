import { Outlet, useLocation } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { AuthUser } from "./context/Context";

const Layout = () => {
  const { user } = AuthUser();
  const location = useLocation();
  return (
    <>
      <NavBar user={user} />
      <div
        className={`w-full ${
          location.pathname === "/"
            ? ""
            : "2xl:w-[60rem] mx-auto px-[0.5rem] mt-5}"
        }`}
      >
        <Outlet />
      </div>
    </>
  );
};

export default Layout;

import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../firebase/firebase";
import { toast } from "react-toastify";
import { AuthUser } from "../context/Context";

const menu = [
  {
    label: "Home",
    path: "/",
  },
  // {
  //   label: "Profile",
  //   path: "/",
  // },
  {
    label: "About",
    path: "/about",
  },
  // {
  //   label: "Resources",
  //   path: "/resources",
  // },
  // {
  //   label: "Join",
  //   path: "/join",
  // },
  {
    label: "Log in",
    path: "/login",
  },
];

export const NavBar = ({ user }) => {
  const navigate = useNavigate();

  let location = useLocation();
  const [active, setActive] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const signOut = async () => {
    try {
      await auth.signOut();
      navigate("/login");
      toast.success("Signed out successfully");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div
      id="navbar"
      className={`md:fixed ${
        location.pathname === "/" ? "mb-0 " : "mb-5 border-b bg-white z-10"
      } pb-2 md:pb-0  md:border-b-0 md:mb-0   md:bg-transparent top-0 w-full`}
    >
      <div className={`flex justify-between w-full items-center px-2 md:px-8`}>
        <h1
          className="font-semibold text-4xl cursor-pointer"
          onClick={() => navigate("/")}
        >
          waypost
        </h1>

        <div className="hidden md:inline-block">
          <div className="flex items-center gap-4 text-sm">
            {!user &&
              menu.map((item, i) => (
                <button
                  key={i}
                  onClick={() => {
                    navigate(item.path);
                    setActive(i);
                  }}
                  className={`text-gray-800 text-lg hover:underline transition-all duration-300`}
                >
                  {item.label}
                </button>
              ))}
            {user && (
              <>
                <button
                  className={`text-gray-900 hover:underline transition-all duration-300 font-medium`}
                  onClick={() => navigate(`/member/${user.id}`)}
                >
                  Home
                </button>
                <button
                  className={`text-gray-900 hover:underline transition-all duration-300 font-medium ${
                    location.pathname === "/settings" ? "underline" : ""
                  }`}
                  onClick={() => navigate("/write")}
                >
                  New post
                </button>
                <button
                  className={`text-gray-900 hover:underline  transition-all duration-300 font-medium`}
                  onClick={signOut}
                >
                  Log out
                </button>
              </>
            )}
          </div>
        </div>

        {/* MOBILE MENU */}
        <div className="md:hidden">
          <button className="text-black" onClick={() => setShowMenu(true)}>
            <i className={`bi bi-list`} style={{ fontSize: 30 }}></i>
          </button>
        </div>

        <div
          className={`fixed inset-0 z-50 
          ${
            showMenu ? "translate-x-0 " : "translate-x-full"
          } transition duration-300 `}
        >
          <div className={`h-screen bg-black/75 backdrop-blur-lg shadow-xl`}>
            <div className="flex justify-center p-1">
              <button
                className="text-black bg-gray-100 h-8 w-8 rounded-full transition-all mt-3 mb-10"
                onClick={() => setShowMenu(false)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>

            <div className="flex items-center flex-col gap-4 ">
              {!user &&
                menu.map((item, i) => (
                  <button
                    key={i}
                    onClick={() => {
                      navigate(item.path);
                      setShowMenu(false);
                    }}
                    className={`text-white text-lg w-full border-b border-gray-200 pb-4 font-semibold`}
                  >
                    {item.label}
                  </button>
                ))}
              {user && (
                <>
                  <button
                    className={`text-white text-lg w-full border-b border-gray-200 pb-4 font-semibold`}
                    onClick={() => {
                      navigate("/");
                      setShowMenu(false);
                    }}
                  >
                    Home
                  </button>
                  <button
                    className={`text-white text-lg w-full border-b border-gray-200 pb-4 font-semibold ${
                      location.pathname === "/settings" ? "underline" : ""
                    }`}
                    onClick={() => navigate("/write")}
                  >
                    New post
                  </button>
                  <button
                    className={`text-white  text-lg w-full border-b border-gray-200 pb-4 font-semibold`}
                    onClick={signOut}
                  >
                    Log out
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CancelButton = ({ children, disabled, onClick }) => {
  return (
    <button
      className="p-2 rounded hover:bg-gray-200 font-semibold transition-all duration-300"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

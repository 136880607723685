import { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [showArrow, setShowArrow] = useState();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`fixed right-10 bottom-10 transition-all duration-500 
  ${showArrow ? "translate-x-0" : "translate-x-20"}
  `}
    >
      <button
        onClick={scrollUp}
        className={`bg-black/50 backdrop-blur-xl rounded-full h-12 w-12 text-white cursor-pointer hover:bg-black p-2 ${
          showArrow ? "" : "invisible"
        }`}
      >
        <i className="bi bi-chevron-bar-up"></i>
      </button>
    </div>
  );
};

export default BackToTopButton;

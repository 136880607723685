import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./outlets/Home";
import Login from "./outlets/Login";
import Error404 from "./outlets/Error404";
import Write from "./outlets/Write";
import { AuthUser } from "./context/Context";
import About from "./outlets/About";
import SinglePost from "./outlets/SinglePost";
import { Join } from "./outlets/Join";
import Member from "./outlets/Member";

function App() {
  const { user } = AuthUser();

  return (
    <Routes>
      <Route element={<Layout />}>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Member />} />
        <Route path="/post/:postId" element={<SinglePost />} />
        <Route path="/login" element={<Login />} />
        <Route path="/join" element={<Join />} />
        <Route path="/about" element={<About />} />
        {user && <Route path="/write" element={<Write />} />}
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
}

export default App;

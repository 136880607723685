import { useEffect, useState } from "react";
import { PrimaryButton } from "../components/PrimaryButton";
import { SecondaryButton } from "../components/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
import { CancelButton } from "../components/CancelButton";
import Loader from "../components/Loader";
import { getAuth, onAuthStateChanged } from "firebase/auth";

//LOGIN FORM
const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgot, setForgot] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("All fields are required");
    }
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      navigate(`/`);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="center">
      {!forgot ? (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <span>Log into your Waypost</span>
          <input
            type="email"
            className="p-2 border border-gray-400 rounded"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="relative flex items-center">
            <input
              type={`${show ? "text" : "password"}`}
              className="p-2 border border-gray-400 rounded w-full"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute right-1 hover:bg-gray-100 rounded-full h-9 w-9"
              onClick={() => setShow(!show)}
            >
              <i className={`bi ${show ? "bi-eye-slash" : "bi-eye"}`}></i>
            </button>
          </div>

          <PrimaryButton type="submit">Log in</PrimaryButton>

          <div>
            <button
              className="text-green-500 hover:underline text-sm"
              onClick={() => setForgot(true)}
            >
              Forgot password?
            </button>
          </div>

          {/* <div className="border-b border-gray-200 my-3" />
          <span className="text-sm">Don't have an account?</span>
          <SecondaryButton onClick={() => navigate("/join")}>
            Join for free
          </SecondaryButton> */}
        </form>
      ) : (
        <Forgot setForgot={setForgot} />
      )}
    </div>
  );
};

export default Login;

//PASSWORD RESET FORM
function Forgot({ setForgot }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Check your email!");
      setLoading(false);
      setForgot(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <form onSubmit={handleReset} className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 my-10">
        <p className="leading-6">
          Enter the email you joined with and we'll send you a link to reset
          your password.
        </p>
        <p className="leading-6">
          Note, if you don't see the email in your Inbox then check your Spam
          folder.
        </p>
      </div>

      <input
        required
        type="email"
        className="p-2 border border-gray-400 rounded"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <PrimaryButton type="submit">Password reset</PrimaryButton>

      <CancelButton onClick={() => setForgot(false)}>Cancel</CancelButton>
    </form>
  );
}

import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { AuthUser } from "../context/Context";
import { db, storage } from "../firebase/firebase";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import katex from "katex";
import "katex/dist/katex.min.css";
import { toast } from "react-toastify";
import AddTags from "./AddTags";
import { useNavigate } from "react-router-dom";

window.katex = katex;

const Write = () => {
  const navigate = useNavigate();
  const { user } = AuthUser();
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const imageRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [photo, setPhoto] = useState("");
  const [tags, setTags] = useState("");
  const [posting, setPosting] = useState(false);
  const [caption, setCaption] = useState("");

  const submitPost = async () => {
    try {
      if (content === "") {
        toast.info("Input cannot be empty");
      } else {
        setPosting(true);
        const collections = collection(db, "posts");

        //images
        let url;
        if (imageUrl) {
          const storageRef = ref(storage, `image/${user.uid}/${photo.name}`);
          await uploadBytes(storageRef, photo);
          url = await getDownloadURL(storageRef);
        }
        //
        await addDoc(collections, {
          userId: user?.uid,
          title,
          content,
          tags,
          postImg: url || "",
          caption,
          created: Date.now(),
          pageViews: 0,
        });
        setPosting(false);
        setTitle("");
        setContent("");
        setStep(1);
        navigate("/");
      }
    } catch (error) {
      toast.error(error.message);
      setPosting(false);
    }
  };

  const handleClick = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    //default scroll to top when page loads
    const scrollTop = () => {
      window.scrollTo(0, 0);
    };
    scrollTop();
  }, []);

  // const toolbarOptions = [
  //   [
  //     "bold",
  //     "italic",
  //     "underline",
  //     "strike",
  //     "blockquote",
  //     "code-block",
  //     "link",
  //   ], // toggled buttons

  //   [
  //     // { color: [] },
  //     // { background: [] },
  //     { list: "ordered" },
  //     { list: "bullet" },
  //   ],
  //   ["formula", { script: "sub" }, { script: "super" }], // superscript/subscript
  //   [{ indent: "-1" }, { indent: "+1" }, { direction: "rtl" }], // outdent/indent

  //   // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  //   [{ align: [] }, "clean"], // dropdown with defaults from theme
  // ];

  const boxStyle = "flex gap-2 flex-col ";

  function Step1() {
    return (
      <>
        <div>
          <textarea
            type="text"
            rows={1}
            autoFocus
            placeholder="Add a headline"
            className="text-xl outline-none border-b border-zinc-200 w-full p-2 font-semibold"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full min-h-[10rem] object-cover bg-gray-200  grid place-items-center cursor-pointer bg-cover bg-center bg-no-repeat"
          onClick={handleClick}
        >
          {imageUrl && (
            <img
              style={{
                backdropFilter: "blur(10px)",
              }}
              className={`object-contain bg-center bg-black/10 w-full h-[400px]`}
              src={imageUrl}
              alt={"feature-image"}
            />
          )}

          {!imageUrl && (
            <div className="flex items-center gap-2">
              <i className="pi pi-image"></i>Add Image
            </div>
          )}
        </div>

        <input
          ref={imageRef}
          type="file"
          hidden
          onChange={(e) => {
            setImageUrl(URL.createObjectURL(e.target.files[0]));
            setPhoto(e.target.files[0]);
          }}
        />

        {imageUrl && (
          <div className="">
            <input
              type="text"
              placeholder="Add caption for the image"
              className="text-xs outline-none border-b border-zinc-200 w-full p-2 italic"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            />
          </div>
        )}
      </>
    );
  }

  function Step2() {
    return (
      <>
        <div>
          <span>Add tags</span>

          <AddTags tags={tags} setTags={setTags} />
        </div>
      </>
    );
  }

  return (
    <div className="w-full">
      {/* SECTION A */}
      <div className={`${boxStyle}md:mb-[3em]`}>
        {step === 1 && (
          <div id="toolbar">
            <select className="ql-size">
              <option value="small"></option>
              <option selected></option>
              <option value="large"></option>
              <option value="huge"></option>
            </select>

            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
            <button className="ql-blockquote" />
            <button className="ql-link" />

            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />

            <button className="ql-script" value="sub"></button>
            <button className="ql-script" value="super"></button>

            <button className="ql-formula" />
            <button className="ql-code-block" />
          </div>
        )}

        <div className={`${boxStyle} p-[1em] `}>
          {step === 1 && Step1()}

          {step === 2 && Step2()}
        </div>

        <div className="p-[0.5em]">
          {step === 1 && (
            <ReactQuill
              theme="snow"
              modules={{ toolbar: "#toolbar" }}
              value={content}
              onChange={setContent}
              placeholder="Write some content"
            />
          )}

          {step === 1 && (
            <div className="flex gap-2 items-center justify-end px-2 mt-5 text-sm">
              <button
                onClick={() => {
                  if (content) {
                    setStep(2);
                  } else {
                    toast.info("Input fields cannot be empty");
                  }
                }}
                className="transition-all duration-300 px-3 py-2 rounded font-semibold bg-indigo-200 hover:bg-indigo-300"
              >
                Continue
              </button>
            </div>
          )}
          {step === 2 && (
            <div className="flex gap-2 items-center justify-end px-2 mt-5 text-sm">
              <button
                onClick={submitPost}
                className="flex items-center gap-2  hover:bg-indigo-300 transition-all duration-300 px-3 py-2 rounded font-semibold bg-indigo-200"
              >
                {posting ? "Posting" : "Post"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Write;

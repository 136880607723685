//Part 14
import { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { readTime } from "../utils/helper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useSingleFetch from "../hooks/useSingleFetch";

const Recommended = ({ post: singlePost }) => {
  const { allPosts } = useFetch("posts");
  const { data } = useSingleFetch("users", "fkxkksUoTaSGhc9P9JQRpHNVRn22");
  const [commonTags, setCommonTags] = useState([]);

  let recommendedPost = [];

  useEffect(() => {
    allPosts &&
      allPosts?.forEach((post) => {
        if (post.id === singlePost.id) {
          //omits the same post from recommended
          return;
        }
        const postTag = post?.tags;

        const commonTags =
          postTag && postTag.filter((tags) => singlePost.tags?.includes(tags));

        if (commonTags.length > 0) {
          recommendedPost.push({
            //pushes to the above recommendedPost empty array
            ...post,
            commonTags,
          });
        }
      });
    recommendedPost.sort(() => Math.round() * -0.5); //generate random posts
    const minRecommendation = 4;
    const slicePost = recommendedPost.slice(0, minRecommendation); //show only 4
    setCommonTags(slicePost);
    console.log(slicePost);
  }, [allPosts, singlePost]);

  return (
    commonTags.length !== 0 && (
      <div className="mb-10">
        <h2 className="text-lg py-3">Recommended from Waypost</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[2rem] ">
          {commonTags.map((post) => (
            <Post key={post.id} post={post} data={data} />
          ))}
        </div>
      </div>
    )
  );
};

export default Recommended;

const Post = ({ post, data }) => {
  const navigate = useNavigate(null);
  const { title, content, created, id: postId, displayName } = post;

  return (
    <div
      className="w-full cursor-pointer flex items-start flex-wrap gap-4 border border-gray-200 hover:bg-gray-100 transition-all p-3 rounded-lg"
      onClick={() => navigate(`/post/${postId}`)}
    >
      {/* {postImg && (
        <img
          className="w-20 h-20 object-cover rounded-lg"
          src={postImg}
          alt="post-img"
        />
      )} */}

      <div>
        <h2 className="line-clamp-3 font-semibold mb-3">{title}</h2>

        <p className="text-xs font-light">
          By {data.displayName} &#xb7; {moment(created).format("MMM DD, YYYY")}{" "}
          &#xb7; {readTime({ __html: content })} min read
        </p>
      </div>
    </div>
  );
};

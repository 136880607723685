import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/PrimaryButton";
import { toast } from "react-toastify";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import { LoadingButton } from "../components/LoadingButton";
import { CancelButton } from "../components/CancelButton";
import Loader from "../components/Loader";

export const Join = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    displayName: "",
    bio: "",
    location: "",
    usa: true,
    email: "",
    password: "",
    rePassword: "",
    permalink: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form["password"] !== form["rePassword"]) {
      toast.error("Password doesn't match");
    } else {
      setLoading(true);

      try {
        const { user } = await createUserWithEmailAndPassword(
          auth,
          form.email,
          form.password
        );

        await sendEmailVerification(auth.currentUser).catch((err) =>
          toast.error(err)
        );

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
          await setDoc(userRef, {
            userId: user.uid,
            displayName: form.displayName,
            location: form.location,
            email: form.email,
            usa: form.usa,
            userImg: "",
            bio: "",
            created: Date.now(),
            permalink: form.permalink,
          });

          try {
            const permalinkRef = doc(db, "permalink", form.permalink);
            const permalinkDoc = await getDoc(permalinkRef);
            if (!permalinkDoc.exists()) {
              await setDoc(permalinkRef, {
                userId: user.uid,
              });
            }

            navigate("/");
            setLoading(false);
            toast.success("You're all set!");
          } catch (error) {}
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
        console.log(error.message);
        if (error.message.includes("auth/email-already-in-use")) {
          navigate("/login");
        }
      }
    }
  };

  useEffect(() => {
    setShow(false);
    setShow2(false);
  }, [step]);

  const handleContinue = (e) => {
    e.preventDefault();
    if (form.password === form.rePassword) {
      setStep(2);
    } else {
      toast.error("Password does not match");
    }
  };

  function Step1() {
    return (
      <form
        id="step-1"
        autocomplete="off"
        className="flex flex-col gap-5"
        onSubmit={handleContinue}
      >
        <div className="flex flex-col">
          <h2 className="mb-10 text-amber-500 font-medium">
            <i className="bi bi-cone-striped pr-1"></i>
            Waypost is currently in development. Please check back at a later
            date.
          </h2>
          <span className="text-sm">Step 1 of 2</span>
          <span className="text-lg">Create your login</span>
        </div>

        <input
          required
          type="email"
          name="email"
          className="p-2 border border-gray-400 rounded"
          placeholder="Enter Email"
          value={form.email}
          onChange={handleChange}
        />
        <div className="relative flex items-center">
          <input
            required
            name="password"
            minLength={6}
            type={`${show ? "text" : "password"}`}
            className="p-2 border border-gray-400 rounded w-full"
            placeholder="New Password"
            value={form.password}
            onChange={handleChange}
          />
          <button
            type="button"
            className="absolute right-1 hover:bg-gray-100 rounded-full h-9 w-9"
            onClick={() => setShow(!show)}
          >
            <i className={`bi ${show ? "bi-eye-slash" : "bi-eye"}`}></i>
          </button>
        </div>
        <div className="relative flex items-center">
          <input
            required
            name="rePassword"
            minLength={6}
            type={`${show2 ? "text" : "password"}`}
            className="p-2 border border-gray-400 rounded w-full"
            placeholder="Confirm Password"
            value={form.rePassword}
            onChange={handleChange}
          />
          <button
            type="button"
            className="absolute right-1 hover:bg-gray-100 rounded-full h-9 w-9"
            onClick={() => setShow2(!show2)}
          >
            <i className={`bi ${show2 ? "bi-eye-slash" : "bi-eye"}`}></i>
          </button>
        </div>

        <PrimaryButton type="submit">Continue</PrimaryButton>
        <CancelButton onClick={() => navigate("/")}>Cancel</CancelButton>
      </form>
    );
  }
  function Step2() {
    return (
      <form
        className="flex flex-col gap-5"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-2 mb-10">
          <span className="text-sm">Step 2 of 2</span>
          <span className="text-lg font-semibold leading-6">
            User information
          </span>
          <p>
            Information you enter in this section is displayed on your Waypost
            page for public visitors.
          </p>
        </div>

        <div>
          <span className="text-sm">
            Enter how you want your name to be displayed
          </span>
          <input
            required
            type="text"
            name="displayName"
            className="p-2 border border-gray-400 rounded w-full"
            placeholder="Display name"
            value={form.displayName}
            onChange={handleChange}
          />
        </div>

        <div>
          <span className="text-sm">
            Write a clear, impactful and professional bio or skip for now
          </span>
          <textarea
            type="text"
            name="bio"
            className="p-2 border border-gray-400 rounded w-full"
            placeholder="Professional bio"
            value={form.bio}
            onChange={handleChange}
          />
        </div>

        <div>
          <span className="text-sm">
            State/Province and Country of residence
          </span>
          <input
            type="text"
            name="location"
            className="p-2 border border-gray-400 rounded w-full"
            placeholder="Location"
            value={form.location}
            onChange={handleChange}
          />
        </div>

        <PrimaryButton type="submit">Submit</PrimaryButton>

        <CancelButton onClick={() => setStep(1)}>Go Back</CancelButton>
      </form>
    );
  }

  return (
    <div className="center">
      {step === 1 && !loading && Step1()}
      {step === 2 && !loading && Step2()}
      {loading && <Loader />}
    </div>
  );
};

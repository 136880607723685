import loadingImg from "../assets/loading.png";

const Loader = () => {
  return (
    <div className="flex items-center justify-center center">
      <img className="h-24 animate-spin" src={loadingImg} alt="loading" />
    </div>
  );
};

export default Loader;

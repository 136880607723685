export const PrimaryButton = ({ children, disabled, type, onClick }) => {
  return (
    <button
      className="bg-black py-2 px-3 rounded text-white font-semibold"
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
